/////////////////////////////////////
/////////////////////////////////////
$(document).on('click', '#burger-menu', openResponsiveEmergentMenu);
$(document).on('click', '#responsive-close-opt', closeResponsiveEmergentMenu);
$(document).on('click', '#responsive-menu .nav-list', closeResponsiveEmergentMenu);
$(document).on('click', '.send-client-form', sendClientForm);
////////////////
$(document).ready(function(){
    getSocialMedia();
    getPhones();
    getServices();
    getAddresses();
    if($('#testimonials-container').length > 0) getTestimonals();
    if($('#blogs-container').length > 0) getLastBlogPost();
    if($('#certifications-allies-container').length > 0) getcertificateAndAllie();
});
function ToggleMenu(){
    $('#menu-container').css('display', 'none');
    setTimeout(function(){
        if($('#menu-container').hasClass('menu-container-small')){
            $('#menu-container').removeClass('menu-container-small').addClass('menu-container-large');
        }else{
            $('#menu-container').removeClass('menu-container-large').addClass('menu-container-small');
        }
        $('#menu-container').css('display', 'block');
    }, 10);
}
function closeResponsiveEmergentMenu(){
    $('#responsive-menu').animate({
        left: '-100%'
    }, 500, function(){
        $('#responsive-menu').css('display', 'none');
    });
}
function openResponsiveEmergentMenu(){
    $('#responsive-menu').css('display', 'block').css('left', '-100%');
    $('#responsive-menu').animate({
        left: '0%'
    }, 500);
}
function getSocialMedia(){
    GetMethodFunction('/web-api/contacts/social-media/get',null, showSocialMedia, null);
}
function showSocialMedia(response){
    var header_html = '';
    var footer_html = '';
    var section_html = '';
    $.each(response.data, function(index, item){
        //Header
        header_html += '<li class="header-social-media-item">';
            header_html += '<a target="_blank" href="'+item.url+'">';
                header_html += '<img src="/'+item.path+'" alt="'+item.name+'" title="'+item.name+'">';
            header_html += '</a>';
        header_html += '</li>';
        //Footer
        footer_html += '<li class="footer-social-media-item">';
            footer_html += '<a target="_blank" href="'+item.url+'">';
                footer_html += '<img src="/'+item.path+'" alt="'+item.name+'" title="'+item.name+'">';
            footer_html += '</a>';
        footer_html += '</li>';
        //Section
        section_html += '<li class="social-media-list-item">';
            section_html += '<a target="_blank" href="'+item.url+'">';
                section_html += '<img src="/'+item.path+'" alt="'+item.name+'" title="'+item.name+'">';
            section_html += '</a>';
        section_html += '</li>';
    });
    $('#header-social-media-list').html(header_html);
    $('#footer-social-media-list').html(footer_html);
    $('#section-social-media-list').html(section_html);
    
}
function getPhones(){
    GetMethodFunction('/web-api/contacts/phone/get',null, showPhones, null);
}
function showPhones(response){
    var html_header = '';
    var html_footer = '';
    //Section
    var html_section = '<li class="phone-list-item">';
    html_section += '<a class="phone-list-link">';
    html_section += '<i class="fas fa-phone"></i>';
    html_section += '<div class="phone-list-text-container">';
    html_section += '<p class="phone-list-text">Teléfonos:</p>';
    $.each(response.data, function(index, item){
        //Header
        html_header += '<li class="header-phone-item">';
            html_header += '<a href="tel:'+item.phone+'" class="d-flex justify-content-center">';
                html_header += '<span class="header-phone-icon align-self-center"><i class="fas fa-phone"></i></span>';
                html_header += '<p class="header-phone-text align-self-center">'+item.phone+'</p>';
            html_header += '</a>';
        html_header += '</li>';
        //Footer
        html_footer += '<li class="footer-phone-item">';
            html_footer += '<a href="tel:'+item.phone+'" class="footer-link">';
                html_footer += '<span class="footer-phone-text align-self-center">'+item.phone+'</span>';
            html_footer += '</a>';
        html_footer += '</li>';
        //Section
        html_section += '<p class="phone-list-text">'+item.phone+'</p>';
                
    });
    //Section
    html_section += '</div>';
    html_section += '</a>';
    html_section += '</li>';
    $('#header-phone-list').html(html_header);
    $('#footer-phone-list').html(html_footer);
    $('#section-phone-list').html(html_section);
    
}
function getServices(){
    GetMethodFunction('/web-api/services/get',null, showServices, null);
}
function showServices(response){
    var html_footer = '<a class="footer-title">Servicios</a>';
    var client_form_services = '<option value="" disabled selected>Selecciona un servicio</option>';  
    $.each(response.data, function(index, item){
        //Client form services
        client_form_services += '<option value="'+item.title+'">'+item.title+'</option>';
        //Footer
        html_footer += '<a href="/" class="footer-link">'+item.title+'</a>';
    });
    //Client form services
    $('#contact-service').html(client_form_services);
    //Footer
    $('#footer-services-col').html(html_footer);
    
}
function getAddresses(){
    GetMethodFunction('/web-api/contacts/address/get',null, showAddresses, null);
}
function showAddresses(response){
    var htmlSection = '';
    var htmlFooter = '';
    $.each(response.data, function(index, item){
        //Section
        htmlSection += '<li class="adresss-list-item">';
            htmlSection += '<a class="adresss-list-link">';
                htmlSection += '<i class="fas fa-map-marker-alt align-self-start"></i>';
                htmlSection += '<div class="adresss-list-text-container align-self-start">';
                    htmlSection += '<p class="adresss-list-name">'+item.name+':</p>';
                    htmlSection += '<p class="adresss-list-address">- '+item.address+'</p>';
                htmlSection += '</div>';
            htmlSection += '</a>';
        htmlSection += '</li>';
        //Footer
        htmlFooter += '<div class="footer-address-item">';
            htmlFooter += '<a class="footer-address-link footer-link">';
                htmlFooter += '<span>'+item.name+'</span><br>';
                htmlFooter += '<span>- '+item.address+'</span>';
            htmlFooter += '</a>';
        htmlFooter += '</div>';
    });
    //Section
    $('#section-adresss-list').html(htmlSection);
    //Footer
    $('#footer-address-list').html(htmlFooter);
    
}
//Certificates and allies
function getcertificateAndAllie(){
    GetMethodFunction('/web-api/certificates-and-allies/get',null, showcertificateAndAllie, null);
}
function showcertificateAndAllie(response){
    var certificateAndAllieList = response.data;
    var appendHtml = '';
    $.each(certificateAndAllieList, function(index, certificateAndAllie){
        appendHtml += '<li class="certifications-allies-list-item align-self-center">';
            appendHtml += '<img src="'+certificateAndAllie.path+'" alt="Certification" class="certifications-allies-list-item-image">';
        appendHtml += '</li>';
    });
    $('#certifications-allies-list').html(appendHtml);
}
//Teestimonials
$(document).on('click', '#testimonials-list-indicators .testimonials-list-indicators-item', showTestimonialsPage);
var testimonalsList = [];
var testimonialsPages = 0;
var currentTestimonialsPage = 0;
function getTestimonals(){
    GetMethodFunction('/web-api/testimonials/get',null, showTestimonals, null);
}
function showTestimonals(response){
    testimonalsList = response.data;
    testimonialsPages = Math.ceil(testimonalsList.length / 2);
    currentTestimonialsPage = 0;
    //set list indicators
    var appendHtml = '';
    for(var i = 0; i < testimonialsPages; i++){
        appendHtml += '<li class="testimonials-list-indicators-item non-active-indicator" index="'+i+'"></li>';
    }
    $('#testimonials-list-indicators').html(appendHtml);
    //////////////////////////
    showTestimonialsCurrentPage();
}
function showTestimonialsPage(){
    currentTestimonialsPage = $(this).attr('index');
    showTestimonialsCurrentPage();
}
function showTestimonialsCurrentPage(){
    testimonialsPerPage = 2;
    //show testimonials in groups of testimonialsPerPage
    var appendHtml = '';
    var start = currentTestimonialsPage * testimonialsPerPage;
    var end = start + testimonialsPerPage;
    for(var i = start; i < end; i++){
        if(testimonalsList[i] != undefined){
            appendHtml += '<li class="testimonials-list-item">';
                appendHtml += '<p class="testimonials-list-list-text">'+testimonalsList[i].comment+'</p>';
                appendHtml += '<div class="testimonials-list-item-author-container d-flex justify-content-center">';
                    appendHtml += '<img src="/'+testimonalsList[i].path+'" alt="Testimonial author" class="testimonials-list-item-author-image">';
                    appendHtml += '<div class="testimonailas-list-item-text align-self-center">';
                        appendHtml += '<p class="testimonials-list-item-author-name">'+testimonalsList[i].name+'</p>';
                        appendHtml += '<p class="testimonials-list-item-author-bussiness">'+testimonalsList[i].business+'</p>';
                        appendHtml += '<p class="testimonials-list-item-author-location">'+testimonalsList[i].location+'</p>';
                    appendHtml += '</div>';
                appendHtml += '</div>';
            appendHtml += '</li>';
        }
    }
    $('#testimonials-list').animate({opacity: 0}, 200, function(){
        $('#testimonials-list').html(appendHtml);
        $('#testimonials-list').animate({opacity: 1}, 200);
    });
    //set active indicator
    $('.active-indicator').removeClass('active-indicator').addClass('non-active-indicator');
    $('#testimonials-list-indicators .testimonials-list-indicators-item[index="'+currentTestimonialsPage+'"').addClass('active-indicator');

}
//Blog
$(document).on('click', '#blogs-container #blog-content #blog-left-arrow', showPreviousBlog);
$(document).on('click', '#blogs-container #blog-content #blog-right-arrow', showNextBlog);
var blogList = [];
var currentBlogIndex = 0;
function getLastBlogPost(){
    let DataSend = {
        limit: 10
    };
    PostMethodFunction('/web-api/blog/get-last-blogs',DataSend,null, setLastBlogPost, null);
}
function setLastBlogPost(response){
    blogList = response.data;
    showLastBlogPost();
}
function showLastBlogPost(){
    if(blogList[currentBlogIndex] != undefined){
        $('#blogs-container #blog-content').animate({opacity: 0}, 200, function(){
            $('#blogs-container #blog-content #blog-content-title').text(blogList[currentBlogIndex].title);
            $('#blogs-container #blog-content #blog-content-data').text(blogList[currentBlogIndex].brief);
            $('#blogs-container #blog-content #blog-view-more').attr('href', '/noticias/'+blogList[currentBlogIndex].url);
            $('#blogs-container #blog-content #blog-content-image').attr('src', '/'+blogList[currentBlogIndex].path);
            $('#blogs-container #blog-content').animate({opacity: 1}, 200);
        });
    }
}
function showPreviousBlog(){
    if(currentBlogIndex > 0){
        currentBlogIndex--;
        showLastBlogPost();
    }else{
        currentBlogIndex = blogList.length - 1;
        showLastBlogPost();
    }
}
function showNextBlog(){
    if(currentBlogIndex < blogList.length - 1){
        currentBlogIndex++;
        showLastBlogPost();
    }else{
        currentBlogIndex = 0;
        showLastBlogPost();
    }
}
//Client form
function sendClientForm(){
    var name = $('#contact-name').val();
    var company = $('#contact-company').val();
    var email = $('#contact-email').val();
    var phone = $('#contact-phone').val();
    var service = $('#contact-service').val();
    var message = $('#contact-message').val();
    var terms_and_conditions = $('#contact-tym').is(':checked');
    var flag = true;
    if(name == '' || name == null){
        flag = false;
        $('#contact-name').addClass('is-invalid');
        alertWarning('El campo nombre es obligatorio');
    }else{
        $('#contact-name').removeClass('is-invalid');
    }
    if(company == '' || company == null){
        flag = false;
        $('#contact-company').addClass('is-invalid');
        alertWarning('El campo empresa es obligatorio');
    }else{
        $('#contact-company').removeClass('is-invalid');
    }
    if(email == '' || email == null){
        flag = false;
        $('#contact-email').addClass('is-invalid');
        alertWarning('El campo correo electrónico es obligatorio');
    }else{
        $('#contact-email').removeClass('is-invalid');
    }
    if(phone == '' || phone == null){
        flag = false;
        $('#contact-phone').addClass('is-invalid');
        alertWarning('El campo teléfono es obligatorio');
    }else{
        $('#contact-phone').removeClass('is-invalid');
    }
    if(service == '' || service == null){
        flag = false;
        $('#contact-service').addClass('is-invalid');
        alertWarning('El campo servicio es obligatorio');
    }else{
        $('#contact-service').removeClass('is-invalid');
    }
    if(message == '' || message == null){
        flag = false;
        $('#contact-message').addClass('is-invalid');
        alertWarning('El campo mensaje es obligatorio');
    }else{
        $('#contact-message').removeClass('is-invalid');
    }
    if(!terms_and_conditions){
        flag = false;
        alertWarning('Debe aceptar los términos y condiciones');
    }
    if(flag){
        $('.send-client-form').attr('disabled', true);
        let DataSend = {
            name: name,
            company: company,
            email: email,
            phone: phone,
            service: service,
            message: message,
            terms_and_conditions: terms_and_conditions

        };
        PostMethodFunction('/web-api/client-form/add',DataSend,null, showClientFormResponse, function(){
            $('.send-client-form').attr('disabled', false);
        });
    }
}
function showClientFormResponse(response){
    $('.send-client-form').attr('disabled', false);
    $('#contact-name').val('');
    $('#contact-company').val('');
    $('#contact-email').val('');
    $('#contact-phone').val('');
    $('#contact-service').val('');
    $('#contact-message').val('');
    $('#contact-tym').prop('checked', false);
    swallMessage(
        'Tu mensaje ha sido enviado, pronto nos pondremos en contacto.'
        , null
        , 'success'
        , null
        , null
        , 3000
        , null
        , null
    );
}